@use "../../styles/partials/mixins.scss" as *;
@use "../../styles/partials/letters" as *;

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 16rem;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: 5rem;
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  border: 3px solid #323232;
  overflow: auto;
  background-color: #002037;
  color: #fefefe;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include tablet {
    width: 26rem;
  }
}

.box-terms {
  position: relative;
  width: 16rem;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: 5rem;
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  border: 3px solid #323232;
  overflow: auto;
  background-color: #002037;
  color: #fefefe;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  scrollbar-width: thin;
  overflow-x: hidden;

  @include tablet {
    width: 26rem;
    scrollbar-width: thin;
    overflow-x: hidden;
  }
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  background: #323232;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #323232;
  font-size: 1.4rem;
  align-self: flex-end;
}
