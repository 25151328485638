@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/letters' as *;

.marker-modal {
    position: absolute;
    border-radius: 1rem;
    background-color: #323232;
    color: #fefefe;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    margin: 1rem;

    @include tablet{
        margin: 1rem 0rem 0rem 13rem;
    }

    &__close {
        cursor: pointer;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
    }

    &__button{
        display: none;

        @include desktop{
            display: block;
            text-align: center;
            border-radius: 0.5rem;
            background-color: #0095FF;
            border: none;
            font-family: "avenir-next", sans-serif;
            font-weight: normal;
            cursor: grab;
            width: 20%;
            height: 2rem;
            margin: 1rem;
            color: #fffefe;
            font-size: 1rem;
        }

    }

    &__button-mobile-tablet{
        text-align: center;
        border-radius: 0.5rem;
        background-color: #0095FF;
        border: none;
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        cursor: grab;
        width: 70%;
        height: 2rem;
        margin: 1rem;
        color: #fffefe;
        font-size: 1rem;
    }

    &__join-button-container{
        display: flex;
        justify-content: center;
        flex-basis: 100%;
    }

    &__join-button-container-hidden{
        display: none;
    }

    &__join-button-container-mobile-tablet{
        display: flex;
        justify-content: center;
    }

    &__join-button-container-mobile-tablet-hidden{
        display: none;
    }

    &__pop-up-button-container{
        display: flex;
        gap: 1rem;
        justify-content: center;
    }

    &__pop-up-button{
        border-radius: 1rem;
        background-color: #0095FF;
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        color: #fefefe;
        padding: 1rem;
        cursor: grab;
        border: none;
        font-size: 1rem;
    }

    &__pop-up-header{
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        color: #fefefe;
        font-size: 1.1rem;
        text-align: center;
    }

    &__logo-pop-up{
        max-width: 7rem;
        align-self: center;
    }

    &__icon-div-cleanups{
        display: flex;
        gap: 0.5rem;
        align-items: center;
        background: #0095FF;
        border-radius: 0.5rem;
        padding: 0.2rem;
        cursor: grab;
        align-items: center;
        align-self: center;
    }

    &__clean-ups-button{
        text-align: center;
        border-radius: 0.5rem;
        background-color: #0095FF;
        border: none;
        font-family: "avenir-next", sans-serif;
        font-weight: bold;
        cursor: grab;
        color: #fffefe;
        font-size: 0.8rem;
        padding: 0.5rem;

    }

    &__button-icon{
        max-width: 1.5rem;
        cursor: grab;
    }
  }

.clean-earth-logo{
    max-width: 7rem;
    padding-bottom: 1rem;
  }

  .marker-modal-images-container{
    display: flex;
    justify-content: center;
  }


.header-text{
    font-family: "avenir-next", sans-serif;
    font-weight: bold;
    font-size: 0.6rem;
    color: #0095FF;

    @include tablet{
        font-size: 1rem;
    }
}

.headers-container{
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 0.5rem;
}

.par-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    @include tablet{
        width: 17rem;
        gap: 0.5rem;
        width: auto;
    }
}

.par{
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    font-size: 0.6rem;
    color: #fefefe;
    text-align: right;
    padding-left: 2rem;
    max-width: 41ch;

    @include tablet{
        font-size: 1rem;
    }
}

.everything-container{
    display: none;

    @include desktop{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

}

.everything-container-mobile-tablet{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @include desktop{
        display: none;
    }
}

.par-mobile{
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    font-size: 0.8rem;
    color: #fefefe;
}

.header-text-mobile{
    font-family: "avenir-next", sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
    color: #0095FF;

    @include tablet{
        font-size: 1rem;
    }
}


.marker-modal img:hover{
    box-shadow: none;
}