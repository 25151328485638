@use "../../styles/partials/mixins.scss" as *;
@use "../../styles/partials/letters" as *;

.volunteer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #002037;
  align-items: center;
  border-radius: 1rem;
  width: 100%;
  border: 3px solid #323232;

  &__heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    color: #fffefe;
    margin-bottom: 3rem;
  }

  &__labels {
    font-size: 1.3rem;
    color: #fffefe;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    padding-bottom: 0.5rem;
  }

  &__text {
    color: #fffefe;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    text-align: center;
    max-width: 44ch;
    font-size: 0.9rem;

    @include tablet {
      max-width: 61ch;
      font-size: 1rem;
    }
  }

  &__heading {
    color: #fffefe;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    text-align: center;

    @include tablet {
      font-size: 1.5rem;
      font-size: 2rem;
    }
  }

  &__button {
    padding: 0 1rem;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    background-color: #2e66e5;
    border-radius: 1rem;
    color: #fffefe;
    border: none;
    cursor: grab;
    height: 2rem;
    width: 35%;

    &:hover {
      background-color: #fefefe;
      color: #002037;
    }

    @include tablet {
      width: 25%;
    }
  }

  &__button-container {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 1rem;
    width: 100%;
    border-top: 3px solid #2e66e5;
    padding-top: 1rem;
  }

  &__input {
    text-align: center;
    padding: 0.5rem;
    border-radius: 1rem;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    color: #323232;
    width: 15rem;
    border: 1px solid #bdc5d5;
    margin-bottom: 2rem;

    &:focus {
      outline-color: #2e66e5;
    }
  }

  &__input-error {
    padding: 0.5rem;
    border-radius: 1rem;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    color: #323232;
    width: 15rem;
    border: 3px solid #ff0000;
    margin-bottom: 2rem;

    &:focus {
      outline-color: #ff0000;
    }
  }

  &__error-message {
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    color: #ff7f7f;
    margin: 0;
    position: relative;
    top: -2rem;
    border-radius: 1rem;
    padding: 0.25rem;

    @include desktop {
      text-align: center;
    }
  }

  &__message {
    color: #fffefe;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    font-size: 1.5rem;
    padding-top: 1rem;
  }

  &__lat-long-error-message-red {
    font-family: "avenir-next", sans-serif;
    font-weight: bold;
    font-size: 0.9rem;
    color: #ff7f7f;
    margin: 0;
    border-radius: 1rem;
    padding: 0.25rem;

    @include tablet {
      font-size: 1.5rem;
    }
  }

  &__text-map {
    border-bottom: 3px solid #2e66e5;
    margin-bottom: 1rem;
    color: #fffefe;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    text-align: center;
    padding-bottom: 1rem;
  }

  &__par-container-above-map {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;

    @include tablet {
      gap: 0rem;
    }
  }

  &__form-everything-container {
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
    align-items: center;

    @include tablet {
      border-bottom: 3px solid #2e66e5;
      margin-bottom: 2rem;
      flex-direction: row;
      gap: 0.5rem;
      width: 96%;
      align-items: center;
      justify-content: center;
    }

    @include desktop {
      gap: 0rem;
      align-self: center;
      width: 100%;
      justify-content: center;
      align-items: baseline;
    }
  }

  &__inputs-container-city-state-country {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    @include tablet {
      min-width: 30%;
      align-items: center;
      justify-content: center;
    }

    @include desktop {
      border-right: 3px solid #2e66e5;
      border-left: 3px solid #2e66e5;
      min-width: 30%;
      align-items: center;
      justify-content: center;
    }
  }

  &__inputs-container-name-email {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    @include tablet {
      min-width: 30%;
      align-items: center;
      justify-content: center;
    }

    @include desktop {
      min-width: 30%;
      align-items: center;
      justify-content: center;
    }
  }

  &__inputs-container-time-date {
    border-bottom: 3px solid #2e66e5;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    @include tablet {
      border-bottom: none;
      margin-bottom: 0;
      min-width: 30%;
      align-items: center;
      justify-content: center;
    }

    @include desktop {
      min-width: 30%;
      align-items: center;
      justify-content: center;
    }
  }

  &__input-time-date {
    padding: 0.5rem;
    border-radius: 1rem;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    color: #323232;
    width: 8rem;
    border: 1px solid #bdc5d5;
    margin-bottom: 2rem;
    height: 2.2rem;

    &:focus {
      outline-color: #2e66e5;
    }

    @include desktop {
      height: 2.1rem;
    }
  }

  &__input-error-time-date {
    padding: 0.5rem;
    border-radius: 1rem;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    color: #323232;
    width: 8rem;
    border: 3px solid red;
    margin-bottom: 2rem;
    height: 2.5rem;

    &:focus {
      outline-color: red;
    }

    @include desktop {
      height: 2.3rem;
    }
  }

  &__login-button {
    font-size: 0.8rem;
    text-align: center;
    border-radius: 0.5rem;
    background-color: #2e66e5;
    border: none;
    font-family: "avenir-next", sans-serif;
    font-weight: bold;
    cursor: grab;
    width: 8rem;
    color: #fffefe;
    padding: 0.5rem;
  }

  &__login-button:hover {
    background-color: #fefefe;
    color: #002037;
  }

  &__no-user-logged-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    background-color: #002037;
    padding: 1rem;
    border-radius: 1rem;
    border: 3px solid #323232;
    width: 100%;

    @include tablet {
      width: 45rem;
    }

    @include desktop {
      width: 75rem;
    }
  }

  &__sign-in-please {
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    color: #fefefe;
    text-align: center;
    font-size: 1rem;

    @include tablet {
      font-size: 1.5rem;
    }
  }

  &__sign-in-please-map {
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    color: #ff0000;
    text-align: center;
    margin-top: 2rem;
    font-size: 1rem;

    @include tablet {
      font-size: 1.5rem;
    }
  }

  &__registered {
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    color: #fefefe;
    text-align: center;
    font-size: 1rem;

    @include tablet {
      font-size: 1.5rem;
    }
  }

  &__terms-text {
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    color: #fefefe;
    text-align: center;
    font-size: 1rem;

    @include tablet {
      font-size: 1.5rem;
    }
  }

  &__terms-text-final {
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    color: #fefefe;
    text-align: center;
    font-size: 0.8rem;

    @include tablet {
      font-size: 0.9rem;
    }
  }

  &__no-user-logged-everything {
    display: flex;
    flex-direction: column;
  }

  &__button-no-login {
    padding: 0 1rem;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    background-color: #2e66e5;
    border-radius: 1rem;
    color: #fffefe;
    border: none;
    height: 2rem;
    width: 35%;

    @include tablet {
      width: 25%;
    }
  }

  &__sign-in-please-logo {
    max-width: 45%;

    @include tablet {
      max-width: 20%;
    }

    @include desktop {
      max-width: 15%;
    }
  }
}

.terms-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.terms-button {
  border-radius: 1rem;
  background-color: #0095ff;
  font-family: "avenir-next", sans-serif;
  font-weight: normal;
  color: #fefefe;
  padding: 1rem;
  cursor: grab;
  border: none;
  font-size: 1rem;
}

.terms-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.terms-list-items {
  font-family: monospace;
  font-size: 1rem;
}
