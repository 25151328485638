@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/letters' as *;

.sign-up{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #002037;
    align-items: center;
    border-radius: 1rem;
    width: 100%;
    border: 3px solid #323232;

    @include tablet{
        width: 80%;
    }

    @include desktop{
        width: 45%;
    }

    &__heading-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        color: #fffefe;
        max-width: 57ch;
        margin-bottom: 3rem;
    }

    &__labels{
        align-self: flex-start;
        padding-left: 1rem;
        color: #fefefe;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        padding-bottom: 0.5rem;

        @include tablet{
            padding-left: 6rem;
        }

        @include desktop{
            padding-left: 4rem;
        }
    }

    &__input{
        padding: 0.5rem;
        border-radius: 1rem;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        font-size: 0.9rem;
        color: #323232;
        width: 100%;
        margin-bottom: 2rem;
    
        &:focus{
            outline-color: #2E66E5;
        }

        @include tablet{
            width: 75%;
        }
    }

    &__text{
        color: #fffefe;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        text-align: center;
    }

    &__heading{
        color: #fffefe;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        font-size: 2rem;
    }

    &__button{
        padding: 0 1rem;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        background-color: #2E66E5;
        border-radius: 1rem;
        color: #fffefe;
        border: none;
        cursor: grab;
        height: 2rem;
        width: 40%;

        @include tablet{
            width: 25%;
        }

    }

    &__button:hover{
        background-color: #fefefe;
        color: #002037;
    }

    &__button-container{
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
        margin-top: 1rem;
        width: 100%;
        border-top: 1px solid #fffefe;
        padding-top: 1rem;
        align-items: center;
        flex-wrap: wrap;

        @include tablet{
            flex-wrap: nowrap;
        }
    }

    &__input-error{
        padding: 0.5rem;
        border-radius: 1rem;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        font-size: 0.9rem;
        color: #323232;
        width: 100%;
        border: 3px solid red;
        margin-bottom: 2rem;

        &:focus{
            outline-color: red;
        }

        @include tablet{
            width: 75%;
        }
    }

    &__error-message{
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        font-size: 0.9rem;
        color: #ff7f7f;
        margin: 0;
        position: relative;
        top: -2rem;
        border-radius: 1rem;
        padding: 0.25rem;
    }

    &__password-error-message{
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        font-size: 0.9rem;
        color: #ff7f7f;
        margin: 0;
        position: relative;
        top: -2rem;
        border-radius: 1rem;
        padding: 0.25rem;
        text-align: center;
    }

    &__show-password{
        min-width: 2rem;
        cursor: grab;
    }

    &__show-hide-password-container{
        display: flex;
        gap: 0.5rem;
        align-items: flex-start;
        width: 100%;
        justify-content: center;

        @include tablet{
            padding-left: 1rem;
        }

    }

    &__registered{
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        color: #fefefe;
        text-align: center;
        font-size: 1rem;

        @include tablet{
            font-size: 1.5rem;
        }
    }

}

.signup-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__log-in{
        width: 22rem;
        border-radius: 0.5rem;
        text-align: center;
        padding: 0.5rem;
        color: #fefefe;
    }
  }
  
  .signup {
    display: flex;
    flex-direction: column;
    width: 22rem;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 2rem 1.5rem;
    margin-bottom: 1rem;
  
    &__title {
        margin-top: 0;
        margin-bottom: 1.5rem;
        text-align: center;
        font-size: 1.5rem;
        border-bottom: 5px solid #ffcf00;
        padding-bottom: 0.5rem;
        align-self: center;
        color: #fefefe;
    }
  
    &__button {
        margin-top: 1rem;
  
        background-color: #1358db;
        border: 1px solid #1358db;
        color: #fff;
        border-radius: 0.5rem;
        padding: 0.5rem;
        font-size: inherit;
        cursor: pointer;
        transition: background-color 0.15s;
  
        &:hover {
            background-color: #003fc2;
        }
    }
  
    &__message {
        color: rgb(30, 132, 30);
        font-size: 1.5rem;
        padding-top: 1rem;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
    }
  }

  .PhoneInputInput{
    padding: 0.5rem;
    border-radius: 1rem;
    font-family: 'avenir-next', sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    color: #323232;
    border: 1px solid #BDC5D5;

    &:focus{
        outline-color: #2E66E5;
    }
  }

  .PhoneInputInput-error{
    padding: 0.5rem;
    border-radius: 1rem;
    font-family: 'avenir-next', sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    color: #323232;
    border: 3px solid red;
    margin-bottom: 2rem;
    text-align: center;

    &:focus{
        outline-color: red;
    }

  }

  .PhoneInput{
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;

    @include tablet{
        width: 75%;
    }
  }

  .PhoneInputCountrySelectArrow{
    color: #fefefe;
  }

  .drNucU > span{
    color: #ff7f7f;
    opacity: 1;
  }
