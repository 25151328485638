@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/letters' as *;

.App{
    height: 1rem;
}

.map-container{
    width: 100%;
    height: 100vh;

    @include desktop{
        height: 50rem;
    }
}

.gm-svpc{
    display: none;
}

.gm-fullscreen-control{
    display: none;
}

.map-header{
    font-size: 0.9rem;
    font-family: "avenir-next", sans-serif;
    font-weight: bold;
    text-align: center;
    color: #fefefe;
    max-width: 31ch;


    @include tablet{
        font-size: 1rem;
        align-self: flex-end;
        max-width: 60ch;
    }

    @include desktop{
        font-size: 1.5rem;
    }
}

.map-par{
    font-size: 0.8rem;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    text-align: center;
    color: #fefefe;
    max-width: 31ch;


    @include tablet{
        max-width: 60ch;
        font-size: 0.75rem;
    }

    @include desktop{
        font-size: 1.1rem;
    }
}

.map-par-mobile{
    font-size: 0.8rem;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    text-align: center;
    color: #fefefe;
    max-width: 31ch;

    @include tablet{
        max-width: 60ch;
        font-size: 0.75rem;
    }

    @include desktop{
        font-size: 1.1rem;
    }

}

.above-map-container{
    flex-direction: column-reverse;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-top: 3px solid #323232;
    justify-content: space-between;
    padding: 3.5rem 0.5rem 0rem 0.5rem;
    background-color: #002037;
    border-bottom: 3px solid #323232;

    @include tablet{
        gap: 0rem;
        flex-direction: row;
    }

    @include desktop{
        padding: 3.5rem 3rem 0rem 3rem;
    }

}

.par-container-above-map{
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include tablet{
        gap: 0rem;
    }

}

// .gm-style-moc{
//     display: none;
// }

.gmnoprint {
    display: none;
}

.gm-style {
    background-color: #002037;
}


img:hover[src="https://maps.gstatic.com/mapfiles/transparent.png"]{
    box-shadow: rgb(0 149 255 / 50%) 10em 10em inset;
    border-radius: 20rem;
}

.map-join-par{
    font-size: 0.9rem;
    color: #fefefe;
    position: absolute;
    top: 0.5rem;
    left: 40%;
    background-color: #0095FF;
    padding: 0.5rem;
    border-radius: 1rem;
    font-family: "avenir-next", sans-serif;
    font-weight: bold;

    @include tablet{
        font-size: 1rem;
    }
}

.map-join-par-hidden{
    display: none;
}

.totals{
    height: 34rem;
    background-color: #0095FF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 3px solid #323232;
    border-bottom: 3px solid #323232;
    flex-wrap: wrap;
    gap: 1rem;

    @include tablet{
        height: 13rem;
    }

    &__title{
        font-family: "avenir-next", sans-serif;
        font-weight: bold;
        color: #fefefe;
        font-size: 0.9rem;

        @include desktop{
            font-size: 1.1rem;
        }

    }

    &__text{
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        color: #fefefe;
        font-size: 0.8rem;
        text-align: center;

        @include desktop{
            font-size: 1rem;
        }
    }

    &__text-container{
        display: flex;
        gap: 1rem;
        justify-content: center;
        flex-direction: column;

        @include tablet{
            flex-direction: row;
        }
    }

    &__container{
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #002037;
        height: 9rem;
        padding: 1rem;
        border-radius: 1rem;
        gap: 0.5rem;
        width: 14rem;

        @include desktop{
            width: 20rem;
        }
    }

    &__picture{
        max-width: 4rem;
    }

    &__picture-world{
        max-width: 6rem;
    }

    &__button-lead{
        background-color: #002037;
        text-align: center;
        border-radius: 0.5rem;
        border: none;
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        cursor: grab;
        color: #fffefe;
        font-size: 1rem;
        flex-basis: 100%;
      
        @include tablet{
          width: 13rem;
          font-size: 1.1rem;
        }
      }

      &__everything-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }

      &__lead-container{
        background-color: #002037;
        display: flex;
        align-items: center;
        gap: 1rem;
        border-radius: 1rem;
        width: 13rem;
        height: 2rem;
        padding: 1rem;
        cursor: grab;
      }
}




