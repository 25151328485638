@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/letters' as *;

.footer{
    background-color: #0095FF;
    padding: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    @include tablet{
        flex-direction: row;
    }

    &__text{
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        color: #002037;
        text-align: center;

        @include tablet{
            text-align: left;
        }
    }

    &__instagram-logo{
        max-width: 2rem;

        @include desktop{
            max-width: 2rem;
        }
    }

    &__twitter-logo{
        max-width: 2rem;

        @include desktop{
            max-width: 2rem;
        }
    }

    &__facebook-logo{
        max-width: 2rem;

        @include desktop{
            max-width: 2rem;
        }
    }

    &__social{
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;

        @include tablet{
            margin-bottom: 0rem;
        }
    }

    &__icon-div{
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    &__phone-icon{
        max-height: 1.2rem;
    }

    &__email-icon{
        max-height: 1.3rem;
    }

    &__everything-container{
        display: flex;
        flex-direction: column;
    }

    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        justify-content: center;

        @include tablet{
            flex-direction: row;
        }
    }

    &__email-button{
        border: none;
        background-color: #0095FF;
        cursor: grab;
        color: #fffefe;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        font-size: 1rem;
    }
    
    &__phone-button{
        border: none;
        background-color: #0095FF;
        color: #fffefe;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        font-size: 1rem;
    }

    &__email-button:hover{
        color: #323232;
    }
    
}