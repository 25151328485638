@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/letters' as *;

.donate{
    display: flex;
    flex-direction: column;
    background-color: #002037;
    text-align: center;
    max-width: 100ch;
    padding: 1.5rem;
    border-radius: 1rem;
    font-size: 0.8rem;
    border: 3px solid #323232;
    max-height: 42rem;
    align-items: center;
    max-height: 57rem;

    @include tablet{
        padding: 2rem;
        font-size: 1rem;
    }

    &__title{
        color: #fffefe;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        font-size: 1.3rem;

        @include tablet{
            font-size: 2rem;
        }

    }

    &__text{
        color: #fffefe;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        text-align: center;
        max-width: 61ch;
        margin-top: 1rem;
    }

    &__cra{
        color: lightgray;
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        align-self: flex-end;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
        text-align: right;
        font-size: 0.6rem;

        @include tablet{
            font-size: 1rem;
        }
    }

    &__container{
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__everything-container{
        gap: 1rem;
        flex-direction: column;
        padding: 1rem;
        background-color: #0095FF;
        display: flex;
        border-radius: 1rem;
        width: 100%;
        align-items: center;

    }

    &__button{
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        padding: 0.5rem;
        border-radius: 0.5rem;
        color: #fffefe;
        background-color: #002037;
        cursor: grab;
        font-size: 0.9rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 1rem;

        @include tablet{
            font-size: 1.2rem;
            width: 14rem;
            height: 5rem;
        }

    }

    &__button:hover{
        background-color: #fefefe;
        color: #002037;
    }

    &__button-monthly{
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        padding: 0.5rem;
        border-radius: 0.5rem;
        color: #fffefe;
        background-color: #002037;
        cursor: grab;
        font-size: 0.9rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 1rem;

        @include tablet{
            font-size: 1.2rem;
            width: 7rem;
            height: 5rem;
        }

    }

    &__button-monthly:hover{
        background-color: #fefefe;
        color: #002037;
    }

    &__button-one-time{
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        padding: 0.5rem;
        border-radius: 0.5rem;
        color: #fffefe;
        background-color: #002037;
        cursor: grab;
        font-size: 0.9rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 1rem;

        @include tablet{
            font-size: 1.2rem;
            width: 14rem;
            height: 5rem;
        }

    }

    &__button-one-time:hover{
        background-color: #fefefe;
        color: #002037;
    }

    &__picture{
        max-width: 33%;
        border-radius: 1rem;
        border: 3px solid #fffefe;
    }

    &__picture-container{
        display: none;

        @include tablet{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            padding-bottom: 0.5rem;
        }

    }

    &__money-container{
        font-family: "avenir-next", sans-serif;
        font-weight: bold;
        font-size: 0.8rem;
        padding: 0.5rem;
        border-radius: 1rem;
        border: 3px solid #323232;
        display: flex;
        align-items: center;
        height: 2rem;
        width: 15rem;
        justify-content: center;

        @include tablet{
            font-size: 0.9rem;
            width: 18rem;
            font-size: 1rem;
        }
    }

    &__money-container-hidden{
        display: none;
    }

    &__options-text{
        font-family: "avenir-next", sans-serif;
        font-weight: bold;
        font-size: 1rem;

        @include tablet{
            font-size: 1.5rem;
        }

    }

    &__options-text-payment{
        font-family: "avenir-next", sans-serif;
        font-weight: bold;
        font-size: 0.9rem;

        @include tablet{
            padding-bottom: 1rem;
            font-size: 1rem;
        }

    }

    &__button-container{
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include tablet{
            border-right: 3px solid #002037;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }

        @include bigger-tablet{
            justify-content: unset;
        }

    }

    &__submit-button{
        padding: 0.5rem;
        border-radius: 1rem;
        color: #fffefe;
        background-color: #0095FF;
        border: none;
        font-size: 0.9rem;
        cursor: grab;
        align-self: flex-end;
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        align-self: center;

        @include tablet{
            font-size: 1rem;
            width: 13rem;
            height: 2.5rem;
        }
    }

    &__money-submit-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }

    &__hidden-div{
        display: none;
    }

    &__hidden-div-show{
        display: block;
        font-family: "avenir-next", sans-serif;
        font-weight: bold;
        font-size: 0.8rem;
        color: #a21919;
        text-align: left;

        @include tablet{
            font-size: 1rem;
        }
    }

    &__pop-up-header{
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        font-size: 1.1rem;

        @include tablet{
            font-size: 2rem;
        }

    }

    &__pop-up-donate-value{
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        font-size: 0.9rem;

        @include tablet{
            font-size: 1.1rem;
        }
    }

    &__donation-options-container{
        display: flex;
        gap: 1rem;

        @include tablet{
            gap: 0rem;
        }
    }

    &__month-container{
        display: flex;
        align-items: center;

        @include tablet{
            padding-left: 0.5rem;
        }

        @include bigger-tablet{
            padding-left: 3.5rem;
        }

    }


}

.clean-earth-logo-pop-up{
    max-width: 5rem;
    align-self: center;

    @include tablet{
        max-width: 7rem;
    }
  }
