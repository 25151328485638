@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/letters' as *;

.login-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    &__sign-up{
        background-color: #002037;
        width: 18rem;
        border-radius: 0.5rem;
        text-align: center;
        padding: 0.5rem;
        border: 3px solid #323232;
        margin-bottom: 1rem;
        font-size: 1.1rem;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        color: #fefefe;

        @include tablet{
            width: 27rem;
        }
    }
  }
  
  .login {
    display: flex;
    flex-direction: column;
    width: 18rem;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    background-color: #002037;
    border-radius: 0.5rem;
    padding: 2rem 1.5rem;
    margin-bottom: 1rem;
    border: 3px solid #323232;

    @include tablet{
        width: 27rem;
    }
  
    &__title {
        margin-top: 0;
        margin-bottom: 1.5rem;
        color: #fefefe;
        text-align: center;
        font-size: 1.5rem;
        border-bottom: 5px solid #ffcf00;
        padding-bottom: 0.5rem;
        align-self: center;
    }
  
    &__button {
        background-color: #1358db;
        border: 1px solid #1358db;
        color: #fff;
        border-radius: 0.5rem;
        padding: 0.5rem;
        cursor: pointer;
        transition: background-color 0.15s;
        font-size: 1rem;
  
        &:hover {
            background-color: #fefefe;
            color: #002037;
        }
    }
  
    &__message {
        border-left: 5px solid #ddd;
        padding: 0.75rem;
        margin-top: 2rem;
    }

    &__input{
        padding: 0.5rem;
        border-radius: 1rem;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        color: #323232;
        width: 100%;
        border: 1px solid #BDC5D5;
        margin-bottom: 2rem;
        text-align: left;
        font-size: 1rem;
    
        &:focus{
            outline-color: #2E66E5;
        }

    }

    &__input-error{
        padding: 0.5rem;
        border-radius: 1rem;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        color: #323232;
        width: 100%;
        border: 3px solid red;
        margin-bottom: 2rem;
        text-align: left;
        font-size: 1rem;

        &:focus{
            outline-color: red;
        }

    }

    &__error-message{
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        font-size: 1rem;
        color: #ff7f7f;
        margin: 0;
        position: relative;
        top: -2rem;
        border-radius: 1rem;
        padding: 0.25rem;
        padding-top: 2rem;
        text-align: center;
    }

    &__error-message-hidden{
        display: none;
    }

    &__labels{
        color: #fefefe;
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        padding-bottom: 0.5rem;
    }

    &__show-password{
        min-width: 2rem;
        cursor: grab;
    }

    &__show-hide-password-container{
        display: flex;
        gap: 0.5rem;
        align-items: flex-start;
    }
  }

  a{
    color: #2E66E5;
    font-family: 'avenir-next', sans-serif;
    font-weight: bold;
  }
  