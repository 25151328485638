@use "../../styles/partials/mixins.scss" as *;
@use "../../styles/partials/letters" as *;

.initiatives {
  background-color: #002037;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 3px solid #323232;

  @include tablet {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    padding: 3rem;
    padding: 0;
  }

  @include desktop {
    padding: 3rem;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
    max-width: 96%;

    @include tablet {
      flex-basis: 45%;
    }

    @include desktop {
      flex-basis: 30%;
    }
  }

  &__picture {
    border-radius: 0.5rem;
    max-height: 25rem;
    border: 3px solid #fffefe;

    @include tablet {
      max-height: 18rem;
    }

    @include desktop {
      max-width: 100%;
    }
  }

  &__content {
    background-color: #bdc1c7;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    height: 20rem;
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    border: 2px solid #323232;

    @include medium-desktop3 {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }

  //   &__content-no-scroll {
  //     background-color: #bdc1c7;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     border-radius: 0.5rem;
  //     margin-top: 0.5rem;
  //     height: 20rem;
  //     overflow: scroll;
  //     overflow-x: hidden;
  //     scrollbar-width: thin;
  //     border: 2px solid #323232;

  //     @include tablet {
  //       overflow-x: hidden;
  //       overflow-y: hidden;
  //     }
  //   }

  //   &__content::-webkit-scrollbar {
  //     width: 0.3rem;
  //   }

  //   &__content::-webkit-scrollbar-thumb {
  //     background-color: #002037;
  //   }

  &__heading {
    font-family: "avenir-next", sans-serif;
    font-weight: bold;
    color: #323232;
    padding-top: 1rem;
    font-size: 1.5rem;
  }

  &__text {
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    color: #323232;
    padding-top: 0.5rem;
    font-size: 0.95rem;
    padding: 1rem;
  }

  &__button {
    text-align: center;
    border-radius: 0.5rem;
    background-color: #0095ff;
    border: none;
    font-family: "avenir-next", sans-serif;
    font-weight: bold;
    cursor: grab;
    width: 35%;
    height: 2rem;
    margin: 1rem;
    color: #fffefe;

    &:hover {
      background-color: #fefefe;
      color: #002037;
    }

    @include tablet {
      width: 50%;
    }

    @include desktop {
      width: 10%;
    }
  }
}
