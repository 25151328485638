@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/letters' as *;

.sign-up-page-background{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-image: url("../../assets/images/loginSignUpBackground.jpg");
    box-shadow: inset 2000em 2000em rgba(50, 50, 50, 0.5);
    padding: 1rem;
    border-top: 3px solid #323232;
    border-bottom: 3px solid #323232;

    @include desktop{
        min-height: 100vh;
    }
}