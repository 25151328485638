@font-face {
    font-family: "avenir-next";
    src: url("../../assets/fonts/AvenirNextLTPro-Regular.woff2") format("woff2"), 
     url("../../assets/fonts/AvenirNextLTPro-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "avenir-next";
    src: url("../../assets/fonts/AvenirNextLTPro-Bold.woff2") format("woff2"),
    url("../../assets/fonts/AvenirNextLTPro-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}
