@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/letters' as *;

.hero{
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: url("../../assets/images/forestPicture3.jpg");
  height: 35rem;
  padding: 4rem 1rem 0rem 1rem;
  border-top: 3px solid #323232;
  border-bottom: 3px solid #323232;

  @include large-desktop{
    height: 60rem;
    padding: 14rem;
  }

  &__text-button-container{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    color: #fffefe;
    background-color: #002037;
    border-radius: 0.5rem;
    margin-left: 0.1rem;
    border: 3px solid #0095FF;
    align-items: center;
    width: 17rem;
  
    @include tablet{
      width: 33rem;
      padding: 2rem;
      margin-left: 2rem;
    }
  
    @include desktop{
      width: 33rem;
      margin-left: 10rem;
    }
  }
  
  &__title{
    font-family: 'avenir-next', sans-serif;
    font-weight: bold;
    font-size: 1rem;
  
    @include tablet{
      font-size: 2rem;
    }
  }
  
  &__text{
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    font-size: 0.9rem;
    padding-top: 0.5rem;
    text-align: left;
    max-width: 26ch;
  
    @include tablet{
      font-size: 1rem;
      padding-top: 0;
      max-width: 47ch;
    }
  }
  
  &__button-lead{
    background-color: #0095FF;
    text-align: center;
    border-radius: 0.5rem;
    border: none;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    cursor: grab;
    color: #fffefe;
    font-size: 1rem;
  
    @include tablet{
      width: 13rem;
      font-size: 1.1rem;
    }
  }

  &__button-join{
    background-color: #0095FF;
    text-align: center;
    border-radius: 0.5rem;
    border: none;
    font-family: "avenir-next", sans-serif;
    font-weight: normal;
    cursor: grab;
    color: #fffefe;
    font-size: 1rem;
  
    @include tablet{
      width: 13rem;
      font-size: 1.1rem;
    }
  }

  &__join-container{
    background-color: #0095FF;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;
    width: 13rem;
    height: 2rem;
    padding: 1rem;
    cursor: grab;
  }

  &__join-container-hidden{
    display: none;
  }

  &__lead-container{
    cursor: grab;
    background-color: #0095FF;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;
    width: 13rem;
    height: 2rem;
    padding: 1rem;
  }

  &__lead-join-container{
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include tablet{
      align-items: flex-start;
      flex-direction: row;
    }
  }

}

