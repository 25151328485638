
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/letters' as *;

.clean-ups-edit-page{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-image: url("../../assets/images/EditCleanUpPageBackground.jpg");
    box-shadow: inset 2000em 2000em rgba(50, 50, 50, 0.5);
    border-top: 3px solid #323232;
    border-bottom: 3px solid #323232;
    padding: 1rem;

    @include desktop{
        min-height: 100vh;
        padding: 2rem;
    }

}