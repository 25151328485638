@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/letters' as *;

.banner{
    display: flex;
    background-color: #0095FF;
    padding: 1rem;
    align-items: flex-end;
    justify-content: center;

    @include tablet{
        align-items: center;
    }

    @include desktop{
        align-items: flex-end;
    }

    &__help-par{
        display: none;

        @include tablet{
            display: block;
            color: #323232;
            font-size: 1rem;
            font-family: 'avenir-next', sans-serif;
            font-weight: normal;
        }


        @include tablet{
            font-size: 1.2rem;
        }

        @include desktop{
            font-size: 1.5rem;
        }
    }

    &__button{
        color: #fffefe;
        border: none;
        font-size: 1rem;
        background-color: #0095FF;
        font-family: 'avenir-next', sans-serif;
        font-weight: bold;
        cursor: grab;
        margin-left: 2rem;

        @include tablet{
            font-size: 1rem;
        }

        @include desktop{
            font-size: 1.5rem;
        }
    }

    &__icon-container{
        display: flex;
        gap: 0.5rem;
        align-items: center;
        cursor: grab;

        @include desktop{
            align-items: flex-end;
        }
    }

    &__donate-icon{
        max-height: 2.3rem;

        @include tablet{
            max-height: 2.4rem;
        }

        @include desktop{
            max-height: 2.6rem;
        }

    }
}