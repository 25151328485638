@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/letters' as *;

.products-list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;

    @include tablet{
        flex-direction: column;
    }

    &_sold-out-coming-soon{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__coming-soon{
        padding-top: 0.5rem;
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        color: #fffefe;
        font-size: 1rem;
    }

    &__container{
        display: flex;
        flex-direction: column;
        padding: 0.5rem 2rem 0.25rem 2rem;
        align-items: center;
        border-radius: 0.5rem;
        flex-basis: 20%;
        margin: 0 1.5rem;
        justify-content: flex-end;
        backdrop-filter: blur(100px);
        text-align: center;
        border: 5px solid #36383C;

        @include tablet{
            margin-bottom: 1rem;
        }

        @include desktop{
            margin-bottom: 0;
            height: 27rem;
        }
    }

    &__everything-container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0rem 0rem 2rem 0;
        flex-direction: column;
        gap: 1rem;

        @include tablet{
            display: block;
            flex-direction: row;
            gap: 0;
        }

        @include desktop{
            display: flex;
        }
    }

    &__shirt{
        max-width: 12rem;
        border-radius: 0.5rem;
        border: 3px solid #fffefe;
    }

    &__bracelet-blue{
        max-width: 12rem;
        border-radius: 0.5rem;
        border: 3px solid #fffefe;
    }

    &__bracelet-green{
        max-width: 12rem;
        border-radius: 0.5rem;
        border: 3px solid #fffefe;
    }

    &__title{
        font-family: "avenir-next", sans-serif;
        font-weight: bold;
        color: #fffefe;
        padding-top: 1rem;
        font-size: 1.2rem;
        border-bottom: 1px solid #0095FF;
        padding: 1.5rem 0 1rem 0;
    }

    &__text{
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        color: #fffefe;
        padding-top: 0.5rem;
        text-align: center;
        max-width: 50ch;
        font-size: 0.9rem;
    }


    &__price{
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        color: #fffefe;
        padding-top: 0.2rem;
    }

    &__picture-button{
        display: flex;
        gap: 1rem;
        justify-content: center;
        margin-top: 1rem;
        width: 100%;
    }

    &__stripe-container{
        padding: 0 1.5rem;
        font-family: "avenir-next", sans-serif;
        font-weight: normal;
        background-color: #002037;
        border-radius: 1rem;
        cursor: grab;
        margin-top: 1rem;
        display: flex;
        align-items: center;
    }

    &__sold-out{
        padding-top: 1.5rem;
        font-family: "avenir-next", sans-serif;
        font-weight: bold;
        color: #fffefe;
        text-decoration: 1px solid line-through red;
        font-size: 1.5rem;
    }
}


.products-list-tag{

    &__container{
        display: flex;
        flex-direction: column;
        background-color: #002037;
        border-radius: 0.5rem;
        justify-content: center;
        color: #fffefe;
        align-content: center;
        flex-basis: 86%;
        margin-bottom: 2rem;
        padding: 2rem;
        margin-top: 2rem;
        border: 3px solid #323232;

        @include tablet{
            max-width: 30.5rem;
        }

        @include desktop{
            max-width: 56rem;
        }

        @include medium-desktop2{
            max-width: 66rem;
        }
    }

    &__title{
        font-family: 'avenir-next', sans-serif;
        font-weight: bold;
        padding-top: 0.5rem;
        border-bottom: 1px solid #0095FF;
    }

    &__text{
        font-family: 'avenir-next', sans-serif;
        font-weight: normal;
        padding-top: 1rem;
        color: #fffefe;
    }
}